<template>
    <page-title
        icon="bi-gear-fill"
        title="カタログマスタ"
    >
    </page-title>

    <section class="section">
        <form @submit.prevent="search()" class="row align-items-end">
            <div class="form-group col-md-8">
                <label>カタログ名</label>
                <form-input
                    v-model="condition.catalog_name"
                ></form-input>
            </div>
            <div class="col-md-4">
                <button-search
                    text="絞り込み"
                    type="submit"
                    :disabled="loading"
                ></button-search>
            </div>
        </form>
    </section>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <table-normal class="table" optional_class="table-auto">
                <thead>
                    <tr class="table-dark">
                        <th class="col-md-2" v-if="isVisible"></th>
                        <th class="col text-center">カタログ名</th>
                        <th class="col-md-4" v-if="isVisible"></th>
                        <th class="col-md-4" v-if="isVisible"></th>
                    </tr>
                </thead>
                <template v-if="catalogs.length">
                    <draggable
                            v-model="catalogs"
                            item-key="catalog_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element, index}">
                            <tr>
                                <td class="align-middle text-center handle" v-if="isVisible">
                                    <i class="bi bi-chevron-expand"></i>
                                </td>
                                <td class="align-middle">
                                    <form-input
                                        v-if="isVisible"
                                        v-model="element.catalog_name"
                                        :required="true"
                                        maxlength="100"
                                        :form="`catalog_${element.catalog_id}`"
                                    ></form-input>
                                    <read-only
                                        v-else
                                        :text="element.catalog_name"
                                        class="text-center"
                                    ></read-only>
                                </td>
                                <td class="align-middle text-end" v-if="isVisible">
                                    <form @submit.prevent="update(element)" :id="`catalog_${element.catalog_id}`">
                                        <button-exec-update
                                            text="保存"
                                        ></button-exec-update>
                                    </form>
                                </td>
                                <td class="align-middle" v-if="isVisible">
                                    <button-exec-delete
                                        type="button"
                                        @click="$refs.confirm_remove.show(); setTargetCatalog(element, index)"
                                    ></button-exec-delete>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <template v-else>
                    <tbody>
                        <tr>
                            <td></td>
                            <td colspan="2"><alert-no-record></alert-no-record></td>
                        </tr>
                    </tbody>
                </template>
                <tfoot v-if="isVisible">
                    <tr>
                        <td class="align-middle"></td>
                        <td class="align-middle">
                            <form-input
                                v-if="isVisible"
                                v-model="additional_catalog.catalog_name"
                                :required="true"
                                maxlength="100"
                                form="add_catalog"
                            ></form-input>
                        </td>
                        <td class="align-middle text-end">
                            <template v-if="isVisible">
                            <form @submit.prevent="create()" id="add_catalog">
                                <button-exec-create
                                    text="追加"
                                ></button-exec-create>
                            </form>
                            </template>
                        </td>
                    </tr>
                </tfoot>
            </table-normal>
        </template>
    </section>

    <confirm-dialog ref="confirm_remove" @ok="remove()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import Catalog from '@/models/entities/catalog';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import ReadOnly from '@/components/forms/ReadOnly';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import TableNormal from '@/components/tables/TableNormal.vue';
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'Catalog',
    components: {
        draggable,
        ConfirmDialog,
        InlineLoader,
        FormInput,
        ReadOnly,
        ButtonSearch,
        ButtonExecCreate,
        ButtonExecUpdate,
        ButtonExecDelete,
        TableNormal,
        AlertNoRecord,
        PageTitle,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
    ],
    data() {
        return {
            //部分ローダー
            loading: false,

            //検索オプション
            condition: {
                catalog_name: null,
            },

            //表示データ
            catalogs: [],

            //新規登録規格前置単位
            additional_catalog: new Catalog(),
            //削除対象規格前置単位
            delete_target: {
                catalog: new Catalog(),
                index: null,
            },
        }
    },
    mounted() {
        //storeに前回の検索条件があれば
        this.condition = this.$store.getters['condition/merge']('Catalog', this.condition);
        this.search();
    },
    computed: {
        isVisible() {
            return this.$store.getters['auth/canManageCatalog']();
        }
    },
    methods: {
        //検索
        search() {
            //表示データ初期化
            this.catalogs.splice(0);
            //部分ローダー
            this.loading = true;

            this.$http.get('/catalog', {
                params: this.condition
            })
            .then(response => {
                for (let row of response.data) {
                    this.catalogs.push(new Catalog(row));
                }
                //検索条件を保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'Catalog',
                    condition: this.condition,
                });
            })
            .finally(() => {
                this.loading = false;
            });
        },
        //削除対象のカタログをセットする
        setTargetCatalog(catalog = new Catalog(), index = null) {
            this.delete_target.catalog = catalog;
            this.delete_target.index = index;
        },
        //新規登録
        create() {
            this.startScreenLoading();

            this.$http.post('/catalog', this.additional_catalog)
            .then(response => {
                this.catalogs.push(new Catalog(response.data));
                this.additional_catalog = new Catalog();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //編集
        update(catalog) {
            this.startScreenLoading();

            this.$http.put(`/catalog/${catalog.catalog_id}`, catalog)
            .then(() => {
                this.showMessage('編集しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/catalog/${this.delete_target.catalog.catalog_id}`)
            .then(() => {
                this.catalogs.splice(this.delete_target.index, 1);
                this.showMessage('削除しました');

                //値をリセット
                this.setTargetCatalog();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //並び替え
        sort() {
            this.startScreenLoading();

            //配列順で順番を採番
            this.catalogs.forEach((catalog, index) => {
                catalog.priority = index + 1;
            });

            this.$http.put(`/catalog/sort`, this.catalogs)
            .then(() => {

            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<style scoped>

</style>
